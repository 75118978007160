var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"answer-sheet"},[_c('v-card-title',{staticClass:"d-flex justify-space-between",class:_vm.isSmAndDown ? 'px-2 py-1' : 'py-2'},[_c('div',[_c('div',{staticClass:"d-flex align-center"},[_c('div',{class:_vm.isSmAndDown ? 'text-base' : 'text-lg'},[_vm._v(" "+_vm._s(_vm.workbook.title)+" "),(_vm.isConfirm)?_c('span',[_vm._v("검토")]):_vm._e()]),_c('div',{staticClass:"ml-2",class:_vm.isSmAndDown ? 'text-sm' : 'text-base'},[_vm._v(" 총 "+_vm._s(_vm.workbook.problemCount)+" 문제 ")])]),_c('div',{staticClass:"d-flex mt-3"},[(_vm.activeStep > 1)?_c('v-btn',{staticClass:"mr-1",attrs:{"color":"secondary","small":_vm.isSmAndDown},on:{"click":function($event){_vm.activeStep = _vm.activeStep - 1}}},[_vm._v(" 이전 ")]):_vm._e(),(_vm.activeStep < _vm.stepCount)?_c('v-btn',{staticClass:"mr-1",attrs:{"color":"primary","small":_vm.isSmAndDown},on:{"click":_vm.moveNext}},[_vm._v(" 다음 ")]):_vm._e(),(
						_vm.activeStep == _vm.stepCount ||
						_vm.answerSheet.length === _vm.workbook.problemCount
					)?_c('v-btn',{attrs:{"color":"accent","small":_vm.isSmAndDown},on:{"click":_vm.confirm}},[_vm._v(" 검토 ")]):_vm._e(),_c('div',{staticClass:"d-flex align-center ml-1"},[_c('timer-count-down',{attrs:{"start-counter":_vm.startExam,"end-datetime-utc":_vm.userSchedule.endDatetimeUtc},on:{"time-has-expired":function($event){_vm.timeHasExpired = $event}}})],1)],1)]),_c('div',[(_vm.userSchedule.workbookId)?_c('my-page-license-exam-camera',{attrs:{"workbook-id":_vm.userSchedule.workbookId,"firebase-user":_vm.firebaseUser,"start-streaming":_vm.startExam,"stop-streaming":_vm.stopStreaming}}):_vm._e()],1)]),_c('v-divider'),(!_vm.isConfirm)?_c('v-card-text',{staticClass:"answer-container"},[_c('v-stepper',{attrs:{"elevation":"0"},model:{value:(_vm.activeStep),callback:function ($$v) {_vm.activeStep=$$v},expression:"activeStep"}},[_c('v-stepper-items',_vm._l((_vm.stepCount),function(step){return _c('v-stepper-content',{key:step,staticClass:"pa-0",attrs:{"step":step}},[_vm._l((_vm.problemCountArray.slice(
							(step - 1) * 10,
							(step - 1) * 10 + 10
						)),function(item){return _c('div',{key:item,class:[
							("question-wrapper-" + item),
							item % 2 === 0 ? 'even' : 'odd' ]},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[(_vm.workbook)?_c('multiple-choice-questions',{attrs:{"is-confirm":false,"question-value":_vm.workbook.answer[item - 1],"workbook-id":_vm.workbook.id,"question-number":item},on:{"add-answer-sheet":_vm.addAnswerSheet}}):_vm._e()],1)],1)}),_c('div',{staticClass:"d-flex justify-center mt-3"},[(_vm.activeStep > 1)?_c('v-btn',{staticClass:"mr-1",attrs:{"color":"secondary"},on:{"click":function($event){_vm.activeStep = _vm.activeStep - 1}}},[_vm._v(" 이전 ")]):_vm._e(),(_vm.activeStep < _vm.stepCount)?_c('v-btn',{staticClass:"mr-1",attrs:{"color":"primary"},on:{"click":_vm.moveNext}},[_vm._v(" 다음 ")]):_vm._e(),(
								_vm.activeStep == _vm.stepCount ||
								_vm.answerSheet.length === _vm.workbook.problemCount
							)?_c('v-btn',{attrs:{"color":"accent"},on:{"click":_vm.confirm}},[_vm._v(" 검토 ")]):_vm._e()],1)],2)}),1)],1)],1):_c('v-card-text',{staticClass:"answer-container"},[_vm._l((_vm.problemCountArray),function(item){return _c('div',{key:item},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"},on:{"click":function($event){return _vm.moveToAnswerSheet(item)}}},[_c('multiple-choice-questions',{attrs:{"is-confirm":true,"question-value":_vm.workbook.answer[item - 1],"workbook-id":_vm.workbook.id,"question-number":item,"answer-sheet":_vm.answerSheet},on:{"add-answer-sheet":_vm.addAnswerSheet}})],1)],1)}),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.submit}},[_vm._v("제출")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }