<template>
	<v-card class="answer-sheet">
		<v-card-title
			class="d-flex justify-space-between"
			:class="isSmAndDown ? 'px-2 py-1' : 'py-2'"
		>
			<div>
				<div class="d-flex align-center">
					<div :class="isSmAndDown ? 'text-base' : 'text-lg'">
						{{ workbook.title }}
						<span v-if="isConfirm">검토</span>
					</div>
					<div class="ml-2" :class="isSmAndDown ? 'text-sm' : 'text-base'">
						총 {{ workbook.problemCount }} 문제
					</div>
				</div>
				<div class="d-flex mt-3">
					<v-btn
						v-if="activeStep > 1"
						color="secondary"
						class="mr-1"
						:small="isSmAndDown"
						@click="activeStep = activeStep - 1"
					>
						이전
					</v-btn>
					<v-btn
						color="primary"
						class="mr-1"
						@click="moveNext"
						:small="isSmAndDown"
						v-if="activeStep < stepCount"
					>
						다음
					</v-btn>
					<v-btn
						color="accent"
						@click="confirm"
						:small="isSmAndDown"
						v-if="
							activeStep == stepCount ||
							answerSheet.length === workbook.problemCount
						"
					>
						검토
					</v-btn>
					<div class="d-flex align-center ml-1">
						<timer-count-down
							:start-counter="startExam"
							:end-datetime-utc="userSchedule.endDatetimeUtc"
							@time-has-expired="timeHasExpired = $event"
						/>
					</div>
				</div>
			</div>
			<div>
				<my-page-license-exam-camera
					:workbook-id="userSchedule.workbookId"
					:firebase-user="firebaseUser"
					:start-streaming="startExam"
					:stop-streaming="stopStreaming"
					v-if="userSchedule.workbookId"
				/>
			</div>
		</v-card-title>
		<v-divider />
		<v-card-text class="answer-container" v-if="!isConfirm">
			<v-stepper v-model="activeStep" elevation="0">
				<v-stepper-items>
					<v-stepper-content
						class="pa-0"
						v-for="step in stepCount"
						:step="step"
						:key="step"
					>
						<div
							v-for="item in problemCountArray.slice(
								(step - 1) * 10,
								(step - 1) * 10 + 10,
							)"
							:key="item"
							:class="[
								`question-wrapper-${item}`,
								item % 2 === 0 ? 'even' : 'odd',
							]"
						>
							<v-col cols="12" md="12" sm="12">
								<multiple-choice-questions
									v-if="workbook"
									:is-confirm="false"
									:question-value="workbook.answer[item - 1]"
									:workbook-id="workbook.id"
									:question-number="item"
									@add-answer-sheet="addAnswerSheet"
								/>
							</v-col>
						</div>
						<div class="d-flex justify-center mt-3">
							<v-btn
								v-if="activeStep > 1"
								color="secondary"
								class="mr-1"
								@click="activeStep = activeStep - 1"
							>
								이전
							</v-btn>
							<v-btn
								color="primary"
								class="mr-1"
								@click="moveNext"
								v-if="activeStep < stepCount"
							>
								다음
							</v-btn>
							<v-btn
								color="accent"
								@click="confirm"
								v-if="
									activeStep == stepCount ||
									answerSheet.length === workbook.problemCount
								"
							>
								검토
							</v-btn>
						</div>
					</v-stepper-content>
				</v-stepper-items>
			</v-stepper>
		</v-card-text>
		<v-card-text class="answer-container" v-else>
			<div v-for="item in problemCountArray" :key="item">
				<v-col cols="12" md="12" sm="12" @click="moveToAnswerSheet(item)">
					<multiple-choice-questions
						:is-confirm="true"
						:question-value="workbook.answer[item - 1]"
						:workbook-id="workbook.id"
						:question-number="item"
						:answer-sheet="answerSheet"
						@add-answer-sheet="addAnswerSheet"
					/>
				</v-col>
			</div>
			<div class="d-flex justify-center">
				<v-btn color="primary" @click="submit">제출</v-btn>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { ref, nextTick, watch, computed, onMounted } from '@vue/composition-api'

import store from '@/store'
import { useRouter, getVuetify } from '@core/utils'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketLicenseService from '@/services/MarketLicenseService'

import TimerCountDown from './components/TimerCountDown.vue'
import MyPageLicenseExamCamera from './MyPageLicenseExamCamera.vue'
import MultipleChoiceQuestions from './components/MultipleChoiceQuestions.vue'

const PAGE_PER_QUESTION_LIMIT = 10

export default {
	components: {
		TimerCountDown,
		MultipleChoiceQuestions,
		MyPageLicenseExamCamera,
	},
	props: {},
	setup() {
		const $vuetify = getVuetify()
		const { route, router } = useRouter()
		const courseId = route.value.params.courseId
		const isConfirm = ref(false)
		const workbook = ref([])
		const startExam = ref(false)
		const activeStep = ref(1)
		const stepCount = ref(1)
		const problemCountArray = ref([])
		const answerSheet = ref([])
		const stopStreaming = ref(false)
		const timeHasExpired = ref(false)
		const userSchedule = ref(null)
		const firebaseUser = computed(() => store.getters['auth/getFirebaseUser'])

		const getLicenseExamUserSchedule = async () => {
			try {
				const data = await MarketLicenseService.getLicenseExamUserSchedule({
					courseId: courseId,
					firebaseUid: firebaseUser.value.uid,
				})
				if (!data) {
					const confirm = await warningSwal({
						html: '정상적인 경로가 아닙니다',
						confirmButtonText: '돌아가기',
					})
					if (confirm.isConfirmed) {
						router.push({
							name: 'mypage-license-exam-guide',
							params: {
								courseId: courseId,
							},
						})
					}
					return
				}

				userSchedule.value = data
				if (data && data.answer) {
					answerSheet.value = data.answer
				}

				store.dispatch('mypage/setAnswerSheet', {
					workbookId: userSchedule.value.workbookId,
					answerSheet: answerSheet.value,
				})
			} catch (e) {
				console.error(e)
			}
		}

		const getWorkBookDetail = async () => {
			workbook.value = await MarketLicenseService.getWorkBookDetail(
				userSchedule.value.workbookId,
			)
			stepCount.value = Math.ceil(
				workbook.value.problemCount / PAGE_PER_QUESTION_LIMIT,
			)
			for (let i = 1; i <= workbook.value.problemCount; i++) {
				problemCountArray.value.push(i)
			}
		}

		const addAnswerSheet = answer => {
			const found = answerSheet.value.some(obj => obj.key === answer.key)
			if (found) {
				const index = answerSheet.value.findIndex(obj => obj.key === answer.key)
				answerSheet.value[index].value = answer.value
			} else {
				answerSheet.value.push(answer)
			}

			MarketLicenseService.updateLicenseExamUserScheduleAnswer({
				courseId: courseId,
				firebaseUid: firebaseUser.value.uid,
				answerSheet: answerSheet.value,
			})
		}

		const moveNext = () => {
			for (
				let i = (activeStep.value - 1) * 10 + 1;
				i <= activeStep.value * PAGE_PER_QUESTION_LIMIT;
				i++
			) {
				const temp = []
				const questions = document.getElementsByName('question' + i)
				for (const element of questions) {
					temp.push(element.checked)
				}

				if (!temp.includes(true)) {
					warningSwal({
						html: '문제의 답안지를 모두 작성해주세요',
						allowOutsideClick: true,
					})
					return
				}
			}

			activeStep.value = activeStep.value + 1

			const answerContainerElement =
				document.getElementsByClassName('answer-container')

			answerContainerElement[0].scrollTo(0, 0)
		}

		const moveToAnswerSheet = async questionNumber => {
			const confirm = await confirmSwal(
				`${questionNumber}번 문제를 수정하시겠습니까?<br /> '네' 버튼을 누르면 해당 페이지로 이동합니다`,
			)
			if (confirm.isConfirmed) {
				isConfirm.value = false
				activeStep.value = Math.ceil(questionNumber / PAGE_PER_QUESTION_LIMIT)

				nextTick(() => {
					document
						.querySelector('.v-stepper .question-wrapper-' + questionNumber)
						.classList.add('active')
				})
			}
		}

		const confirm = () => {
			for (
				let i = (activeStep.value - 1) * 10 + 1;
				i <= workbook.value.problemCount;
				i++
			) {
				const temp = []
				const questions = document.getElementsByName('question' + i)
				for (const element of questions) {
					temp.push(element.checked)
				}

				if (!temp.includes(true)) {
					warningSwal({
						html: '문제의 답안지를 모두 작성해주세요',
						allowOutsideClick: true,
					})
					return
				}
			}
			isConfirm.value = !isConfirm.value
		}

		const _submit = async () => {
			try {
				const data = await MarketLicenseService.postWorkbookUserTest(
					userSchedule.value.workbookId,
					answerSheet.value,
				)

				const workbookPayload = {
					courseId: courseId,
					firebaseUid: firebaseUser.value.uid,
				}

				if (userSchedule.value.mockExam) {
					workbookPayload.mockExam = true
					workbookPayload.phoneVerified = false
				} else {
					workbookPayload.finished = true
				}
				await MarketLicenseService.updateLicenseExamUserWorkbook(
					workbookPayload,
				)

				await MarketLicenseService.deleteLicenseExamUserSchedule({
					courseId: courseId,
					firebaseUid: firebaseUser.value.uid,
				})

				store.dispatch('mypage/deleteAnswerSheet', {
					workbookId: userSchedule.value.workbookId,
				})
				isConfirm.value = false
				stopStreaming.value = true

				let message = ''
				if (userSchedule.value.mockExam) {
					if (data.pass) {
						message = '모의고사 시험에 합격하셨습니다.'
					} else {
						message = '모의고사 시험에 불합격하셨습니다.'
					}
				} else {
					if (data.pass) {
						message =
							'IT 마케터 자격증 시험에 합격하셨습니다.<br />자격증은 수 분내 발급 처리됩니다'
					} else {
						message = '아쉽게도 이번 자격증 시험에 불합격 되셨습니다.'
					}
				}

				if (data.pass) {
					await successSwal({
						html: message,
						allowOutsideClick: false,
					})
				} else {
					await warningSwal(message)
				}
				router.push({ name: 'mypage-license-exam-guide' })
			} catch (e) {
				let message = '시험지를 제출하데 문제가 발생했습니다.'
				if (e.response.status === 400) {
					message = e.response.data.message
				}
				warningSwal(message)
			}
		}

		const submit = async () => {
			const confirm = await confirmSwal(
				'시험지를 제출하시겠습니까? 제출하시면 더 이상 답안지를 수정할 수 없습니다',
			)
			if (confirm.isConfirmed) {
				_submit()
			}
		}

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		onMounted(async () => {
			await getLicenseExamUserSchedule()
			await getWorkBookDetail()
			startExam.value = true
		})

		watch(
			() => timeHasExpired.value,
			currentValue => {
				if (currentValue) {
					_submit()
				}
			},
		)

		return {
			isSmAndDown,
			startExam,
			workbook,
			answerSheet,
			activeStep,
			stepCount,
			problemCountArray,
			stopStreaming,
			isConfirm,
			timeHasExpired,
			firebaseUser,
			userSchedule,

			moveNext,
			addAnswerSheet,
			moveToAnswerSheet,
			confirm,
			submit,
		}
	},
}
</script>
<style lang="scss" scoped>
@import url(//fonts.googleapis.com/earlyaccess/nanummyeongjo.css);

.answer-sheet {
	font-family: 'Nanum Myeongjo', serif;

	div {
		font-weight: 600;
		font-size: 16x;
	}
}

.answer-container {
	overflow-y: scroll;
	max-height: 800px;

	@media screen and (max-width: 768px) {
		padding: 0;
	}
}

div[class^='question-wrapper'] {
	min-height: 70px;

	&.active {
		border: 1px solid red;
		border-radius: 5px;
	}

	&.odd {
		background-color: rgb(226, 226, 226, 0.2);
	}
}
</style>
