<template>
	<div class="" style="min-height: 55px">
		<div style="letter-spacing: -1px" class="d-flex align-start mb-4">
			<h2 class="mr-1">{{ questionNumber }}.</h2>
			<strong
				class="text-base ml-2"
				style="position: relative; top: 4px"
				v-html="foramtQuestion(questionValue.question)"
			></strong>
		</div>
		<div v-if="questionValue.question_examples" class="mb-2">
			<h4>&lt;보기&gt;</h4>
			<ul style="list-style: none; font-size: 16px" class="pl-0">
				<li
					v-for="(example, index) in questionValue.question_examples"
					:key="'eample-' + index"
				>
					<span v-if="isValidUrl(example)">
						<img :src="example" style="width: 100%" />
					</span>
					<span v-else>
						{{ example }}
					</span>
				</li>
			</ul>
		</div>
		<div class="d-flex flex-column align-start">
			<label
				v-for="index in totalQuestionCount"
				:key="index"
				class="wrapper items-center align-center question"
			>
				<input
					type="radio"
					:name="'question' + questionNumber"
					:value="index"
					:checked="isChecked(questionNumber, index)"
					:disabled="isConfirm"
					@click="addAnswerSheet(questionNumber, index)"
				/>
				<span class="checkmark">{{ index }}</span>

				<div class="text-base question-choice">
					{{ questionValue.choices[index - 1] }}
				</div>
			</label>
		</div>
	</div>
</template>
<script>
import { isValidUrl } from '@/filter'
import { ref } from '@vue/composition-api'
import store from '@/store'

const TOTAL_QUESTION_COUNT = 4
const QUESTION_UNDERLINE_LIST = [
	'맞게',
	'옳은',
	'옳지 않은',
	'않는',
	'알맞게 짝지어진 것',
	'아닌',
	'다른',
	'없는',
	'순서대로 쓴 것',
	'알맞은',
	'바르게 연결된',
]

export default {
	props: {
		questionValue: {
			required: true,
		},
		isConfirm: {
			type: Boolean,
			default: false,
		},
		questionNumber: {
			type: Number,
			required: true,
		},
		workbookId: {
			type: Number,
			required: true,
		},
		hasTestSubmitted: {
			type: Boolean,
			default: false,
		},
		answerSheet: {
			type: Array,
			default: () => [],
		},
	},
	setup(props, { emit }) {
		const totalQuestionCount = ref(TOTAL_QUESTION_COUNT)

		const addAnswerSheet = (questionNumber, answer) => {
			const data = {
				key: questionNumber - 1,
				value: answer,
			}
			emit('add-answer-sheet', data)
		}

		const isChecked = (questionNumber, _index) => {
			const answerSheet = store.getters['mypage/getAnswerSheet'](
				props.workbookId,
			)
			if (answerSheet.length > 0) {
				const index = answerSheet.findIndex(
					obj => parseInt(obj.key) === questionNumber - 1,
				)
				if (index !== -1) {
					return parseInt(answerSheet[index].value) === _index
				}
			} else {
				return false
			}
		}
		const foramtQuestion = question => {
			let replaceString = ''

			for (let i = 0; i < QUESTION_UNDERLINE_LIST.length; i++) {
				if (question.includes(QUESTION_UNDERLINE_LIST[i])) {
					replaceString = QUESTION_UNDERLINE_LIST[i]
					break
				}
			}

			const formattedQuestion = question.replace(
				replaceString,
				`<span class="underline">${replaceString}</span>`,
			)

			return replaceString ? formattedQuestion : question
		}

		return {
			totalQuestionCount,
			isChecked,

			isValidUrl,

			foramtQuestion,
			addAnswerSheet,
		}
	},
}
</script>
<style lang="scss" scoped>
::v-deep {
	span.underline {
		text-decoration: underline;
		text-underline-position: under;
	}
}

.wrapper {
	display: block;
	position: relative;
	cursor: pointer;
	text-align: center;
	font-size: 22px;
	width: 100%;
	margin-bottom: 24px;

	@media screen and (max-width: 768px) {
		margin-bottom: 15px;
	}
	.question-choice {
		text-align: left;
		position: relative;
		top: 5px;
		left: 40px;
		width: calc(100% - 40px);

		@media screen and (max-width: 768px) {
			top: 0px;
			left: 30px;
			width: calc(100% - 30px);
		}
	}

	/* On mouse-over, add a grey background color */
	&:hover input ~ .checkmark {
		background-color: #ccc;
	}

	/* Hide the browser's default radio button */
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;

		/* When the radio button is checked, add a blue background */
		&:checked ~ .checkmark {
			color: white;
			background-color: #333333;
			transition: 0.4s;
		}
	}

	/* Create a custom radio button */
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		margin: 0 auto;
		height: 32px;
		width: 32px;
		border-radius: 50%;
		background-color: #ffffff;
		border: 1px solid #ccc;

		@media screen and (max-width: 768px) {
			font-size: 16px;
			height: 24px;
			width: 24px;
		}
	}
}
</style>
