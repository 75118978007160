<template>
	<div>
		<video
			ref="video"
			:style="isSmAndDown ? 'max-width: 100px' : 'max-width: 150px '"
		></video>
		<canvas
			ref="canvas"
			width="500"
			height="500"
			style="display: none"
		></canvas>
	</div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import { useRouter, getVuetify } from '@core/utils'
import { warningSwal } from '@/plugins/swalMixin'

import MarketLicenseService from '@/services/MarketLicenseService'

export default {
	props: {
		workbookId: {
			required: true,
		},
		firebaseUser: {
			required: true,
		},
		startStreaming: {
			type: Boolean,
		},
		stopStreaming: {
			type: Boolean,
		},
	},
	components: {},
	setup(props) {
		const $vuetify = getVuetify()
		const video = ref(null)
		const canvas = ref(null)
		const snapshot = ref(null)
		const intervalId = ref(null)
		const { router } = useRouter()
		const constraints = {
			video: true,
			audio: false,
		}

		const detectCameraPermission = async () => {
			try {
				await navigator.mediaDevices.getUserMedia(constraints)
			} catch (e) {
				clearInterval(intervalId.value)

				const confirm = await warningSwal(
					'웹캠을 허용하지 않으면 온라인 시험에 응시할 수 없습니다',
				)

				if (confirm.isConfirmed) {
					router.go(-1)
				}
			}
		}

		const uploadCapturedSnapShot = async (uint8Array, fileName) => {
			try {
				const payload = {
					fileName: fileName,
					firebaseUid: props.firebaseUser.uid,
					workbookId: props.workbookId,
				}

				await MarketLicenseService.uploadCapturedSnapShot(uint8Array, payload)
			} catch (e) {
				console.error(e)
			}
		}

		const captureSnapshot = () => {
			const ctx = canvas.value.getContext('2d')
			ctx.drawImage(video.value, 0, 0, canvas.value.width, canvas.value.height)
			canvas.value.toBlob(async blob => {
				const arrayBuffer = await blob.arrayBuffer()
				const uint8Array = new Uint8Array(arrayBuffer)

				const fileName = `${
					props.firebaseUser.uid
				}-snapshot_${new Date().getTime()}.png`
				await uploadCapturedSnapShot(uint8Array, fileName)
			})
		}

		const startVideoStreaming = async () => {
			const mediaSupport = 'mediaDevices' in navigator

			if (mediaSupport) {
				try {
					const data = await navigator.mediaDevices.getUserMedia(constraints)
					if (data) {
						video.value.srcObject = data
						video.value.play()

						intervalId.value = setInterval(() => {
							if (intervalId.value) {
								detectCameraPermission()
								captureSnapshot()
							}
						}, 5000)
					}
				} catch (e) {
					clearInterval(intervalId.value)
					if (
						e.name == 'NotAllowedError' ||
						e.name == 'PermissionDismissedError'
					) {
						const confirm = await warningSwal(
							'웹캠을 허용하지 않으면 온라인 시험에 응시할 수 없습니다',
						)

						if (confirm.isConfirmed) {
							router.go(-1)
						}
					}
				}
			} else {
				const confirm = await warningSwal(
					'해당 브라우져는 시험시 모니터링에 필요한 웹캠이 지원되지 않습니다.<br /> 온라인 시험 진행시 인터넷 브라우져 환경은 크롬 브라우져로 이용해주세요.',
				)
				if (confirm.isConfirmed) {
					router.push({ name: 'mypage-dashboard' })
				}
			}
		}

		const stopVideoStreaming = () => {
			clearInterval(intervalId.value)

			const videoElem = video.value
			const stream = videoElem.srcObject
			const vidTracks = stream.getVideoTracks()

			vidTracks.forEach(track => {
				track.stop()
			})

			videoElem.srcObject = null
		}

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		watch(
			() => props.startStreaming,
			currentValue => {
				if (currentValue) {
					startVideoStreaming()
				}
			},
			{
				immediate: true,
			},
		)

		watch(
			() => props.stopStreaming,
			currentValue => {
				if (currentValue) {
					stopVideoStreaming()
				}
			},
		)

		return {
			video,
			canvas,
			snapshot,
			isSmAndDown,

			startVideoStreaming,
			stopVideoStreaming,
		}
	},
}
</script>
<style lang="scss" scoped></style>
