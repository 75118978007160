<template>
	<v-container>
		<v-row no-gutters>
			<v-col cols="12" md="12" sm="12">
				<my-page-license-exam-answer-sheet />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import MyPageLicenseExamAnswerSheet from './MyPageLicenseExamAnswerSheet.vue'

export default {
	components: {
		MyPageLicenseExamAnswerSheet,
	},
	setup() {
		window.history.pushState(null, '', window.location.href)
		window.onpopstate = function () {
			window.history.pushState(null, '', window.location.href)
		}
	},
}
</script>
