<template>
	<div class="d-flex align-center">
		<span class="text-base">남은 시간</span>
		<div timer id="timer" class="timer"></div>
	</div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import dayjs from 'dayjs'

export default {
	components: {},
	props: {
		endDatetimeUtc: {
			type: String,
		},
		startCounter: {
			type: Boolean,
		},
	},
	setup(props, { emit }) {
		const timer = ref(null)

		const countdown = () => {
			timer.value = setInterval(() => {
				const timerElem = document.getElementById('timer')
				if (!timerElem) {
					clearInterval(timer.value)
				}

				const distance = dayjs(props.endDatetimeUtc).diff(dayjs(), 'ms')

				const minutes = Math.floor(distance / 60000)
				const seconds = ((distance % 60000) / 1000).toFixed(0)

				timerElem.innerHTML =
					minutes + ':' + (seconds < 10 ? '0' : '') + seconds

				if (distance < 0) {
					clearInterval(timer.value)
					emit('time-has-expired', true)
					timerElem.innerHTML = '시험 종료'
				}
			}, 1000)
		}

		watch(
			() => props.startCounter,
			currentValue => {
				if (currentValue) {
					countdown()
				}
			},
			{
				immediate: true,
			},
		)
	},
}
</script>
<style lang="scss" scoped>
.timer {
	font-weight: bold;
	font-size: 21px;
	margin-left: 5px;
}
</style>
